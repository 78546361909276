<template>
  <div>

    <infoHeader :content_name="'提交故障处理方法'" />

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="故障情况">
        <el-input v-model="form.abnormal_question" placeholder="如 夹臂无法收回，驱动器报红灯，等"></el-input>
      </el-form-item>

      <el-form-item label="故障类型">
        <el-select v-model="form.types" placeholder="请选择">
          <el-option label="不区分" :value="1" ></el-option>
          <el-option label="硬件" :value="2" ></el-option>
          <el-option label="软件" :value="3" ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="故障相关类目">
        <el-select v-model="form.question_type_id" placeholder="请选择">
          <el-option
            label="未知"
            :value="0"
          >
          </el-option>
          <el-option
            v-for="item in option.question_type"
            :key="item.id"
            :label="item.type_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="除标题外相关索引词">
        <el-input v-model="form.question_suit" placeholder="可以写设备名, 设备型号, 等等"></el-input>
      </el-form-item>

    </el-form>

    <el-divider content-position="left">问题解决方法</el-divider>

    <editor v-model="form.resolvent" :content='form.resolvent' uploadkey="questionlib_img"/>

    <el-form ref="form" style="margin-top: 30px">
      <el-form-item>
        <el-button type="primary" @click="Submit">确认无误，立即创建</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import editor from "@/components/Editor.vue";
import { equipmentFaultManageQuestionTypeQuery, equipmentFaultManageSubmitNewQuestion } from "@/api/manage/operation/equipment_fault_manage.js";
// @ is an alias to /src
export default {
    name: '',
    data() {
        return {
            form: {
              abnormal_question: null,
              question_type_id: 0,
              question_suit: null,
              resolvent: null,
              types: 1
            },
            option: {
              question_type: []
            }
        };
    },
    components: {
      editor
    },
    created(){
      this.getOption()
    },
    methods:{
      getOption(){
        equipmentFaultManageQuestionTypeQuery({}).then((res) => {
            this.option.question_type = res.data;
        });
      },
      Submit(){
        equipmentFaultManageSubmitNewQuestion(this.form).then((res) => {
          if (res.code == 200){
            this.$router.push({name:'equipmentFaultManage_Start'})
          }
        });
      },
    }
}
</script>
<style lang="scss" scoped></style>
